import React, { useState, useEffect } from "react"
import BaseLayout from "../components/core/BaseLayout"
import SEO from "../components/seo"
import ConditionDutilisationComponent from "../components/core/conditions-d-utilisation/ConditionsDutilisation"
const ConditionDutilisation = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 0)
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      {isLoading ? (
        <span></span>
      ) : (
        <BaseLayout>
          <SEO title="conditions-d-utilisation" />
          <ConditionDutilisationComponent />
        </BaseLayout>
      )}
    </>
  )
}

export default ConditionDutilisation
