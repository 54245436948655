import React from "react"
import useStyles from "../styles"

const CollecteDesRenseignementsPersonnels = () => {
  const classes = useStyles()

  return (
    <>
      <h3 className={classes.h3Title}>
        Collecte des renseignements personnels
      </h3>
      <p className={classes.subTitleParagraph}>
        Nous collectons les renseignements suivants :
      </p>
      <ul className={classes.listeCollecteDeRenseigment}>
        <li>Nom</li>
        <li>Prénom</li>
        <li>Adresse postale complète</li>
        <li>Adresse électronique</li>
        <li>Numéros de téléphone</li>
        <li>Formation</li>
        <li>Expérience professionnelle</li>
        <li>Curriculum vitae</li>
      </ul>
      <p className={classes.subTitleParagraph}>
        Les renseignements personnels que nous collectons sont recueillis au
        travers de formulaires et grâce à l'interactivité établie entre vous et
        notre site Web et sont emmagasinés sur une base de données sécurisée
        externe à notre site.
      </p>
    </>
  )
}

export default CollecteDesRenseignementsPersonnels
