import React from "react"
import useStyles from "../styles"

const Introduction = () => {
  const classes = useStyles()

  return (
    <>
      <h3 className={classes.h3Title}>Introduction</h3>
      <p className={classes.subTitleParagraph}>
        Devant le développement des nouveaux outils de communication, il est
        nécessaire de porter une attention particulière à la protection de la
        vie privée. C'est pourquoi, nous nous engageons à respecter la
        confidentialité des renseignements personnels que nous collectons.
      </p>
    </>
  )
}

export default Introduction
