import React from "react"
import useStyles from "../styles"

const FormulairesEtInteractivite = () => {
  const classes = useStyles()

  return (
    <>
      <h3 className={classes.h3Title}>Formulaires et interactivité</h3>
      <p className={classes.subTitleParagraph}>
        Vos renseignements personnels sont collectés par le biais de formulaire,
        à savoir :
      </p>
      <ul className={classes.listeCollecteDeRenseigment}>
        <li>Formulaire d'inscriptionà la banque de candidats</li>
      </ul>
      <p className={classes.subTitleParagraph}>
        Nous utilisons les renseignements ainsi collectés pour les finalités
        suivantes :
      </p>
      <ul className={classes.listeCollecteDeRenseigment}>
        <li>Vous envoyer des offres d'emploi correspondant à vos critères</li>
      </ul>
    </>
  )
}

export default FormulairesEtInteractivite
