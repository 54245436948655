import React from "react"
import useStyles from "../styles"

const UtilisationDesCookies = () => {
  const classes = useStyles()

  return (
    <>
      <h3 className={classes.h3Title}>Utilisation des cookies</h3>
      <p className={classes.subTitleParagraph}>
        Pour faciliter l'utilisation de notre site Web et le rendre plus
        agréable, nous utilisons des cookies (cookies de performance, de
        fonctionnalités et publicitaires). Les cookies sont de petits fichiers
        de données qui nous permettent de comparer les nouveaux visiteurs avec
        les anciens, de comprendre comment les utilisateurs naviguent sur notre
        site et d'obtenir des données à partir desquelles nous allons améliorer
        l'expérience de navigation à l'avenir ou vous proposer des postes plus
        pertinents à votre profil.
      </p>
      <p className={classes.subTitleParagraph}>
        Vous pouvez modifier les paramètres de votre navigateur pour bloquer les
        cookies. Si vous ne souhaitez pas modifier votre configuration,
        poursuivez simplement votre visite sur le site. Pour en savoir plus sur
        les cookies et leur incidence sur vous et votre expérience de
        navigation, consultez le site de la CNIL.
      </p>
    </>
  )
}

export default UtilisationDesCookies
