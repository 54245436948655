import React from "react"
import useStyles from "./styles"
import Introduction from "./components/Introduction"
import CollecteDesRenseignementsPersonnels from "./components/CollecteDesRenseignementsPersonnels"
import FormulairesEtInteractivite from "./components/FormulairesEtInteractivite"
import UtilisationDesCookies from "./components/UtilisationDesCookies"

const ConditionDutilisationComponent = () => {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.titleBox}>
        <h1 className={classes.title}>CONDITIONS D'UTILISATION</h1>
      </div>
      <div className={classes.conditionDutilisationContainer}>
        <Introduction />
        <hr className={classes.hr} />
        <CollecteDesRenseignementsPersonnels />
        <hr className={classes.hr} />
        <FormulairesEtInteractivite />
        <hr className={classes.hr} />
        <UtilisationDesCookies />
      </div>
    </div>
  )
}

export default ConditionDutilisationComponent
