import { makeStyles } from "@material-ui/core/styles"
import { fontSize } from "@material-ui/system"

const useStyles = makeStyles(theme => ({
  titleBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#b1cd0a",
  },
  title: {
    padding: "1em 10% ",
    fontSize: "24px",
    lineHeight: "1",
    margin: "0",
    color: "#274654",
    fontWeight: "600",
  },
  conditionDutilisationContainer: {
    padding: "1em 10% ",
  },
  h3Title: {
    fontSize: "24px",
  },
  subTitleParagraph: {
    fontSize: "16px",
  },
  hr: {
    border: "2px solid #dadada",
    marginTop: "20px",
    marginBottom: "20px",
  },
  listeCollecteDeRenseigment: {
    "& li": {
      fontSize: "15px",
    },
  },
}))

export default useStyles
